.scroll-to-top-button {
  /* Initially hidden */
  position: fixed;
  bottom: 20px;
  right: 20px; /* Adjust the position as needed */
  background-color: #4CAF50; /* Green background color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 50%; /* Makes the button a perfect circle */
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  outline: none;
  z-index: 9999;
  transition: background-color 0.3s;
  width: 50px; /* Fixed width */
  height: 50px; /* Fixed height to match width for perfect circle */
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-to-top-button:hover {
  background-color: #a05945; /* Darker color on hover */
}

/* Style for the arrow-up icon */
.scroll-to-top-button::before {
  content: "\2191"; /* Unicode character for an upward arrow */
  font-size: 24px;
}
