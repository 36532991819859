* {
  box-sizing: border-box;
  background-size: cover;
  font-family: "poppins",
}

body {
  height: auto;
  width: auto;
  margin: 0;
  overflow-x: hidden;
}

#bg {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-image: var(--bg);
  filter: grayscale(50%);
  z-index: -4;
}

.blg_hea {
  padding: 30px;
  font-size: 40px;
  text-align: center;
  background: #fff;
}

.blg_left_column {
  float: left;
  width: 100%;
}

.blg_right_column {
  float: left;
  width: 25%;
  padding-left: 20px;
}

.blg_fake {
  background-color: #aaa;
  width: 100%;
  padding: 20px;
}

.blg_card {
  color: #000;
  padding: 13px;
  
}


.blg_row:after {
  content: "";
  display: table;
  clear: both;
}

.blg_foo {
  padding: 20px;
  text-align: center;
  background-image: url("https://www.w3schools.com/w3images/map.jpg");
  margin-top: 20px;
}

@media screen and (max-width: 800px) {

  .blg_left_column,
  .blg_right_column {
    width: 100%;
    padding: 0;
  }
}

/* Center website */
.pg_main {

  margin: auto;
}

h1 {
  font-size: 34px;
  word-break: break-all;
  color: black;
}

.pg_row {
  margin: 8px -16px;
}

/* Add padding BETWEEN each pg_column */
.pg_row,
.pg_row>.pg_column {
  padding: 8px;
}

/* Create four equal pg_columns that floats next to each other */
.pg_column {
  float: left;
  width: 25%;
}

/* Clear floats after rows */
.pg_row:after {
  content: "";
  display: table;
  clear: both;
}

/* Content */
.pg_content {
  background-color: white;
  padding: 10px;
}

/* Responsive layout - makes a two pg_column-layout instead of four pg_columns */
@media screen and (max-width: 900px) {
  .pg_column {
    width: 50%;
  }
}

/* Responsive layout - makes the two pg_columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .pg_column {
    width: 100%;
  }
}

/*TIMELINE*/
.tl {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.tl::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.tl-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

.tl-container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: #fff;
  border: 4px solid #ff9f55;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.tl-left {
  left: 0;
}

.tl-right {
  left: 50%;
}

.tl-left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid #fff;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
}

.tl-right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #fff;
  border-width: 10px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
}

.tl-right::after {
  left: -16px;
}

.tl-content {
  padding: 20px 30px;
  background-color: #fff;
  position: relative;
  border-radius: 6px;
}

@media screen and (max-width: 600px) {
  .tl::after {
    left: 31px;
  }

  .tl-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  .tl-container::before {
    left: 60px;
    border: medium solid #fff;
    border-width: 10px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
  }

  .tl-left::after,
  .tl-right::after {
    left: 15px;
  }

  .tl-right {
    left: 0%;
  }
}




/* codepen specific styling */


.bcca-breadcrumb {
  margin: 0 auto;
  margin-top: 40px;
}

/*** breadcrumb container ***/
.bcca-breadcrumb {
  margin-left: 6px;
  display: flex;
  flex-direction: row-reverse;
  flex-shrink: 0;
  width: fit-content;
  margin-bottom: 15px;
  position: relative;
  border-radius: 4px;
}

/*** breadcrumb items ***/
.bcca-breadcrumb-item {
  transition: all 0.2s ease-in-out;
  height: 40px;
  background: white;
  box-shadow: 0px 0px 18px -2px #d9d9d9;
  line-height: 40px;
  padding-left: 30px;
  padding-right: 10px;
  font-size: 13px;
  font-weight: 600;
  color: #115883;
  position: relative;
  cursor: pointer;
  float: left;
}

.bcca-breadcrumb-item:after {
  transition: all ease-in-out 0.2s;
  content: "";
  position: absolute;
  left: calc(100% - 10px);
  top: 6px;
  z-index: 1;
  width: 0;
  height: 0;
  border: 14px solid #ffffff;
  border-left-color: transparent;
  border-bottom-color: transparent;
  box-shadow: 0px 0px 0 0px #d9d9d9, 5px -5px 10px -4px #d9d9d9;
  transform: rotate(45deg);
  margin-left: -4px;
}

.bcca-breadcrumb-item:last-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 10px;
}

.bcca-breadcrumb-item:first-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: rgba(23, 165, 98, 0.06);
}

.bcca-breadcrumb-item:first-child:after {
  content: "";
  display: none;
}

.bcca-breadcrumb-item i {
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.2);
}

/*** hover breadcrumbs ***/
.bcca-breadcrumb-item:hover {
  background-color: #f9f9f9;
}

.bcca-breadcrumb-item:hover:after {
  border: 14px solid #f9f9f9;
  border-left-color: transparent;
  border-bottom-color: transparent;
}

.bcca-breadcrumb-item:first-child:hover {
  background-color: rgba(23, 165, 98, 0.15);
}


.blg_row {
  margin: 0 auto;
}

.blg_card {
  color: #000;
  padding: 13px;
}

@media (max-width: 576px) {
  .blg_card {
      margin-bottom: 20px; /* Adding space between cards on mobile */
  }
}

@media (max-width: 768px) {
  .blg_card {
      padding: 10px; /* Reduce padding for smaller screens */
  }

  .blg_row .row {
      flex-direction: column; /* Stack columns on mobile */
  }
}

@media (min-width: 768px) {
  .blg_card {
      padding: 20px; /* Add more padding for larger screens */
  }
}
