/* General Blog Section Styles */
.blog-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: center;
    background-color: #f8f8f8;
  }
  
  /* Blog Card Styling */
  .blog-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 350px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Blog Image Styling */
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  /* Blog Content Styling */
  .blog-content {
    padding: 20px;
  }
  
  .blog-content h2 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .blog-content p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  /* Blog Meta Information Styling */
  .blog-meta {
    font-size: 0.9em;
    color: #999;
  }
  
  .blog-meta span {
    margin-right: 10px;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .blog-card {
      width: 100%;
    }
  }
  