/* Wrapper styles */
.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1190px;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

/* Container for the form and text */
.container1 {
  position: relative;
  width: 100%;
  max-width: 1000px;
  height: auto;
  display: flex;
  background: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  flex-direction: row;
  justify-content: space-between;
}

.credit {
  margin: 25px auto 0 auto;
  text-align: center;
  color: #666666;
  font-size: 16px;
}

/* Links */
.credit a {
  color: #222222;
  font-size: 16px;
  font-weight: 600;
}

/* Column styles */
.col-left,
.col-right {
  width: 50%;
  padding: 45px 35px;
}

.col-left {
  background: #105886;
  color: #ffffff;
  clip-path: polygon(98% 17%, 100% 34%, 98% 51%, 100% 68%, 98% 84%, 100% 100%, 0 100%, 0 0, 100% 0);
}

.login-text {
  text-align: center;
}

.login-text h2 {
  font-size: 2rem;
}

.login-form h2 {
  font-size: 1.8rem;
}

/* Form input fields and button */
.login-form input {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-bottom: 15px;
}

.login-form input.btn {
  background: #105886;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.login-form input.btn:hover {
  background: #105886;
  color: white;
}

.login-form p {
  margin: 0 0 20px;
  text-align: justify;
}

.login-form p a {
  color: #30909d;
  text-decoration: none;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .container1 {
    flex-direction: column;
    max-width: 100%;
  }

  .col-left,
  .col-right {
    width: 100%;
    padding: 30px;
    clip-path: none;
  }

  .login-text h2 {
    font-size: 1.5rem;
  }

  .login-form h2 {
    font-size: 1.6rem;
  }

  .login-form input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }
}

/* Media queries for smaller devices */
@media (max-width: 575.98px) {
  .wrapper {
    padding: 10px;
  }

  .col-left,
  .col-right {
    padding: 20px;
  }

  .login-text h2,
  .login-form h2 {
    font-size: 1.4rem;
  }

  .login-text p {
    font-size: 1rem;
  }
}
