.map-banner-section {
    background-color: #f9f9f9; /* Light background for contrast */
}

.Text_Heading1 {
    color: #333;
    font-size: 2.5rem;
}

.Text_fontt {
    font-size: 1.1rem;
    color: #555;
}

.Poster_image {
    max-width: 100%;
    height: auto;
}

