.navbar-light .navbar-nav .nav-link {
    color: rgb(17 88 134) !important;
}

body {
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: inherit!important;
    color: #212529;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

.Card_Top{
    margin-top: -43px!important;
}

a {
    text-decoration: none !important;
    font-family: poppins;
    font-size: 16px;
}

.text-paraa{
        /* margin: 0 0 20px 0; */
        font-size: 16px!important;
        line-height: inherit!important;
        /* text-align: justify; */
        font-weight: 500;
       
}
ul.nav li.dropdown:hover ul.dropdown-menu {
    display: block!important;
}
.navbar-expand-lg .navbar-nav .dropdown-menu:hover {
    /* position: absolute; */
    display: block!important;
}

.LogoImage{
    width: 130px;
    cursor: pointer;
}

.Poster_image {
    margin-left: 123px;
    width: 499px;
    padding: 4px;
}

.Text_Heading{
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 0%;
}
.Text_Heading1{
    font-weight: bold;
    font-size: 35px;
    margin-bottom: 0%;
}
.text_alignment{
    margin-left: -4.6rem!important;
  }


@media screen and (max-width: 600px) {

    .LogoImage{
        width: 60px;
        cursor: pointer;
    }
   
    .Poster_image {
        margin-left: 52px;
        width: 300px;
       
    }
  }

  @media only screen and (max-width: 768px) {
  .text_alignment{
    margin-left: -0.5rem!important;
  }
}