.tax-services-container {
  padding: 40px 20px;
  background-color:white;
}

.title {
  margin-bottom: 30px;
}

.title h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  font-weight: bold;
}

.subtitle {
  font-size: 1.2rem;
  color: #7f8c8d;
  margin-top: 10px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.service-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.card-background {
  position: relative;
  padding: 20px;
  text-align: center;
  border-radius: 15px;
  transition: transform 0.3s ease;
  background-size: cover;
  background-position: center;
  color: black; /* Change text color to black */
}

.card-background:hover {
  transform: scale(1.05);
}

.individual {
  background-image: url('https://source.unsplash.com/random/280x180/?tax,individual');
}

.business {
  background-image: url('https://source.unsplash.com/random/280x180/?tax,business');
}

.consulting {
  background-image: url('https://source.unsplash.com/random/280x180/?tax,consulting');
}

.representation {
  background-image: url('https://source.unsplash.com/random/280x180/?tax,representation');
}

.icon {
  margin-bottom: 10px;
}

.individual-icon {
  color: #3498db; /* Blue color for the individual icon */
}

.business-icon {
  color: #e67e22; /* Orange color for the business icon */
}

.consulting-icon {
  color: #27ae60; /* Green color for the consulting icon */
}

.representation-icon {
  color: #9b59b6; /* Purple color for the representation icon */
}

.service-heading {
  font-size: 1.5rem;
  margin: 10px 0;
}

.service-description {
  font-size: 1rem;
  color: black; /* Ensure description text is black */
}

@media (max-width: 768px) {
  .title h2 {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .service-heading {
    font-size: 1.25rem;
  }

  .service-description {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .service-card {
    padding: 10px;
  }

  .icon {
    padding: 5px;
  }

  .service-heading {
    font-size: 1rem;
  }

  .service-description {
    font-size: 0.85rem;
  }
}
