@media (max-width: 576px) {
    .row {
        flex-direction: column;

    }

    li {
        justify-content: flex-start;

    }

    .col-sm-4 {
        /*   outline: 2px dotted blue;  */
        width: 60% !important;
        margin-bottom: 10px;
    }

    .col-sm-8 {

        margin-bottom: 10px;
    }
}

.col-sm-4 {
    /*   outline: 2px dotted blue;  */

    width: 21.33333%;
}