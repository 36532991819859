/* Existing CSS */

.navbar-nav {
    align-items: center; /* Vertically center all items in the navbar */
}

.nav-item {
    margin-right: 15px; /* Add spacing between nav items */
}

.nav-link {
    padding: 10px 15px; /* Adjust padding for better alignment */
    font-size: 16px; /* Set a uniform font size */
    text-transform: uppercase; /* Capitalize text */
}

.navbar .btn-outline-success {
    margin-left: 15px; /* Space between last nav item and button */
    padding: 8px 20px; /* Adjust padding for button */
    font-weight: 600; /* Bold text for the button */
}

.navbar {
    padding: 10px 0; /* Adjust overall padding of the navbar */
}

/* Responsive Styles */

@media (max-width: 767.98px) {
    .navbar-nav {
        flex-direction: column; /* Stack navbar items vertically on smaller screens */
        text-align: center; /* Center align text in mobile view */
    }

    .nav-item {
        margin-right: 0; /* Remove margin on mobile */
        margin-bottom: 10px; /* Add margin between items */
    }

    .navbar-toggler {
        margin-right: 15px; /* Add margin to the right of the toggle button */
    }

    .navbar .btn-outline-success {
        margin-left: 0; /* Remove margin for the button on mobile */
        margin-top: 10px; /* Add top margin to separate from other items */
    }
}


/* Ensure the dropdown menu appears below the parent item */
.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
}

/* Show dropdown menu on hover */
.nav-item:hover .dropdown-menu {
    display: block;
}

/* Add a little space around the dropdown */
.nav-item .dropdown-menu {
    margin-top: 0.125rem;
}

/* Optional: Add a border or background to dropdown items */
.dropdown-menu .dropdown-item {
    padding: 0.5rem 1rem;
}
